import React from "react";
import {
    Link
} from 'react-router-dom'
import JuryElem from "./juryElem.jsx";

import './juryGrid.css';

//Functional Component 
const JuryGrid = () => {

    return (
    <div className="juryContainer">
        
        <div className="jury">
                <JuryElem mainJury={true} name="Catherine Botti et Jean-Luc Botti" description="Co-présidents de cette édition 2021, le premier couple MOF primeur !" img="juryimg/botti.jpg"/>
        </div>

        <div className="juryGrid">

                      
            <div className="jury">
                <JuryElem name="Charlotte Entraigues" description="MOF Primeur 2018 et Co-organisatrice de cette édition 2021" img="juryimg/entraigues.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Eric Fabre" description="MOF Primeur 2018 et Co-organisateur de cette édition 2021" img="juryimg/fabre.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Clotilde Jacoulot" description="MOF Primeur 2011, Notre Créatrice du concours Corbeilles d’Or" img="juryimg/jacoulot.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Jêrome Durquet" description="MOF Primeur 2015 et Président de l’édition précedente 2019" img="juryimg/durquet.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Geneviève Bellet" description="MOF Primeur 2011" img="juryimg/bellet.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Frédéric Jaunault" description="MOF Primeur 2011" img="juryimg/jaunault.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Johan Favre" description="MOF Primeur 2015" img="juryimg/favre.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Patrice Richard" description="MOF Primeur 2018" img="juryimg/richard.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Florence Carayon" description="MOF Primeur 2011" img="juryimg/carayon.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Maxime Lafranceschina" description="MOF Primeur 2015" img="juryimg/lafranceschina.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Laurent Benakila" description="MOF Primeur 2015" img="juryimg/benakila.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Brigitte Delanghe" description="MOF Primeur 2011" img="juryimg/delanghe.jpg"/>
            </div>
            <div className="jury">
                <JuryElem name="Laurent Petit" description="Chef triplement étoilé et un macaron vert au michelin au restaurant le clos des sens à Annecy le Vieux, notre parrain de cette édition 2021 !" img="juryimg/petit.jpg"/>
            </div>  
        </div>
    </div>
    );
};

export default JuryGrid;