import React from "react";

import './spaceheader.css';

//Functional Component 
const Spaceheader = () => {

    return (
    <div className="spaceheader"/>
    );
};

export default Spaceheader;