import React from 'react';
import styled from 'styled-components';
import {
    Link
} from 'react-router-dom'

import { useLocation } from 'react-router-dom'

import '../navbar.css';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  color: white;
  margin-top: 0px;
  padding: 0px;
  z-index: 2;
  height: 100%;
  @media (max-width: 820px) {
    flex-flow: column nowrap;
    background-color: rgb(192, 168, 88);
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    padding-right: ${({ open }) => open ? '30px' : '0px'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 200px;
    transition: transform 0.2s ease-in-out;
    li {
      color: #fff;
    };
  }
`;

const RightNav = ({ open, setOpen }) => {
  
  const location = useLocation();
  console.log(location.pathname);

  let accueilClassName = "NavBarButton";
  let juryClassName = "NavBarButton";
  let inscriptionClassName = "NavBarButtonParticipation";
  let partenairesClassName = "NavBarButton";

  let activeClass = " NavBarButtonActive";

  if (location.pathname == '/')
  {
    accueilClassName += activeClass;
  }
  else if (location.pathname == '/jury')
  {
    juryClassName += activeClass;
  } 
  else if (location.pathname == '/partenaires')
  {
    partenairesClassName += activeClass;
  }

  return (
    <Ul open={open}>

        <Link to="/" onClick={() => setOpen(!open)}>
            <button className={accueilClassName} >Accueil</button>
        </Link>

        <Link to="/jury" onClick={() => setOpen(!open)}>
            <button className={juryClassName} >Jury</button>
        </Link>

        <Link to="/partenaires" onClick={() => setOpen(!open)}>
            <button className={partenairesClassName} >Partenaires</button>
        </Link>
    </Ul>
  )
}

export default RightNav