import React from 'react';

import NavBar from "../components/navbar.jsx";

import Spacefooter from "../components/spacefooter.jsx";
import Spaceheader from "../components/spaceheader.jsx";


import './legal.css';

const LegalPage = () => {
    return (
        <div className="Container">

            <Spaceheader/>

            <div className="LegalContainer">
            
                <div style={{"textAlign": "center", "alignItem": "center", "justifyContent": "center", "display": "flex", "flex" : "1", "flexDirection": "column"}}>
                    <h1 style={{"color": "white", "alignSelf": "center"}}>Mentions légales</h1>
                </div>

                <h2>Propriété intellectuelle</h2>

                <h3>Hébergement</h3>

                <p>Firebase<br/>1600 Amphitheatre Parkway, Mountain View, California 94043, USA<br/>https://firebase.google.com/.</p>

                <p>Les images de personnes ou de lieux reproduites sur le site sont soit la propriété de l’organisation du concours des Corbeilles d'Or, soit utilisées avec leur autorisation. L’utilisation des images est interdite sous réserve d’une mention contraire contenue dans les mentions légales ou dans la rubrique du site où elles sont situées.</p>

                <p>Vous pouvez, pour votre usage personnel, charger et stocker sur votre disque ou imprimer une copie unique des informations mises à votre disposition sur le site sous réserve du respect des droits de propriété intellectuelle et de tout autre droit de propriété dont il est fait mention.</p>

                <p>Dans le cas contraire, aucun élément composant le site ne peut être copié, reproduit, modifié, réédité, chargé, dénaturé, transmis ou distribué de quelque manière que ce soit, sous quelque support que ce soit, de façon partielle ou intégrale, sans notre autorisation écrite et préalable.</p>

                <p>L’information et les données contenues sur le site internet peuvent cependant faire l’objet d’une traduction ou d’une reproduction à des fins d’études personnelles ou de recherche.</p>

                <p>Tout usage non autorisé pourra constituer une violation à la législation et aux règlements applicables au copyright, aux marques commerciales, à la publicité et à la communication ainsi qu’à la protection de la vie privée et est susceptible de donner lieu à des poursuites  judiciaires.</p>

                <p>Le fait d’accéder au site et de naviguer sur le site constitue une acceptation sans réserve des dites conditions.</p>
                <p>Nous vous recommandons de lire attentivement les conditions d’utilisation suivantes qui s’appliquent à chaque visiteur de ce site internet.</p>
                <p>Ces mentions légales pouvant être modifiées, nous vous engageons à les consulter régulièrement.</p>

                <h2>Liens vers d'autres sites</h2>

                <p>La responsabilité de l’organisation du concours des Corbeilles d'Or ne saurait être engagée au titre d’un site tiers auquel vous auriez accès via le site http://corbeillesdor.com .

                    Nous ne disposons d’aucun moyen de contrôle du contenu de ces sites tiers qui demeurent totalement indépendants.

                    De surcroît, l’existence d’un lien entre le site et un site tiers ne signifie en aucun cas une approbation à quelque titre que ce soit du contenu de ce site et a fortiori de l’usage qui pourrait en être fait.

                    En outre, il incombe à l’utilisateur de prendre les précautions nécessaires pour éviter toute contamination.

                    Des sites externes peuvent contenir des liens hypertextes pointant vers le site. Si vous créez un lien vers http://corbeillesdor.com nous apprécierions d’en être informé par simple mail.

                    Un tel lien ne pourra être installé sans notre accord préalable et exprès. En tout état de cause, nous ne sommes aucunement responsable de l’indisponibilité de tels sites et nous n’examinons, ni ne contrôlons les contenus, publicités, produits ou autres éléments disponibles sur ou via ces sites.</p>

                <h2>Déclaration CNIL</h2>

                <p>Il incombe au Directeur de publication du site internet, de faire toutes les déclarations nécessaires auprès des organismes compétents, sur l’utilisation des données à caractère personnel qu’il contient.</p>

                <h2>Informations nominatives et autres informations</h2>

                <p>Nous ne divulguerons pas à des tiers les données personnelles vous concernant que vous pourriez nous communiquer par le moyen de notre formulaire électronique. Ces données ne seront utilisées que dans le but de vous répondre le plus efficacement possible.
                    Conformément à la loi « Informatique et Liberté » du 6 janvier 1978, vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données personnelles qui vous concernent.
                    Pour cela, il suffit d’en faire la demande :</p>

                <ul>
                    <li>en ligne: via l’adresse organisation@corbeillesdor.com</li>
                </ul>

                <p>Le site n’est pas destiné à recevoir de votre part des informations à caractère confidentiel. En conséquence et à l’exception des données personnelles visées ci-dessus, toute information, quelle qu’en soit la forme, document, donnée, graphique, question, suggestion, concept, remarque, ou autre, que vous nous communiquerez sur le site ne sera en aucun cas tenue pour confidentielle. En conséquence, sa simple transmission par vos soins nous confère le droit de l’utiliser, de la reproduire, de la diffuser, de la modifier ou de la transmettre dans le but de traiter votre demande.</p>

                <h2>Limitations de responsabilité</h2>

                <p>Nous nous efforçons d’assurer au mieux de nos possibilités, l’exactitude et la mise à jour des informations diffusées sur le site. 
                    Nous nous réservons le droit de corriger, à tout moment et sans préavis, le contenu. Toutefois, nous ne pouvons garantir l’exactitude, la précision, la mise à jour ou l’exhaustivité des informations mises à la disposition sur le site.
                    En conséquence, et à l’exception des dommages directs résultant d’une faute lourde ou intentionnelle, nous déclinons toute responsabilité :</p>
                
                <ul>

                    <li>pour toute imprécision, inexactitude ou omission portant sur des informations disponibles sur le site ;</li>

                    <li>pour tous dommages résultant d’une intrusion frauduleuse d’un tiers, ayant entraîné une modification des informations ou éléments mis à la disposition sur le site ;</li>

                    <li>et plus généralement pour tous dommages, directs ou indirects, quelles qu’en soit les causes, origines, natures ou conséquences, quand bien même nous aurions été avisés de la possibilité de tels dommages, provoqués à raison : de l’accès de quiconque au site ou de l’impossibilité d’y accéder, de l’utilisation du site, incluant toutes détériorations ou virus qui pourraient infecter votre équipement informatique ou tout autre bien, et/ou du crédit accordé à une quelconque information provenant directement ou indirectement du site. Les éléments du site ou de tout autre site sont fournis « en l’état » sans aucune garantie d’aucune sorte, implicite ou explicite.</li>

                </ul>

                <h2>Informations disponibles en ligne</h2>

                <p>Les informations diffusées sur ce site web sont fournies par l’organisation du concours Corbeilles d'Or. Elles le sont à titre d’informations et pour un usage personnel uniquement ; elles sont susceptibles d’être modifiées sans avis préalable. Il est à noter qu’il n’est pas possible de garantir qu’un document disponible en ligne reproduise exactement un texte adopté officiellement, de sorte que seule la version officielle ou originale du document est considérée comme faisant foi.</p>

                <h2>Disponibilité du site web</h2>

                <p>Le site est normalement accessible 24h/24h et 7 jours/7. En cas de force majeure, de difficultés informatiques, de difficultés liées à la structure des réseaux de télécommunications ou difficultés techniques, pour des raisons de maintenance, sans que cette liste ne soit exhaustive, l’accès à tout ou partie du site pourra être suspendu ou supprimé sur simple décision de l’organisation du concours des Corbeilles d'Or. Pour des raisons de maintenance, et/ou pour toute autre décision de l’organisation du concours des Corbeilles d'Or, l’accès au site pourra être interrompu. Le site est susceptible de modifications et d’évolutions sans notification d’aucune sorte. Le visiteur du site reconnaît disposer de la compétence et des moyens nécessaires pour accéder à/ et utiliser ce site. Il reconnaît avoir vérifié que la configuration informatique qu’il utilise ne contient aucun virus et qu’elle est en parfait état de fonctionnement.</p>

                <h2>Qu'est-ce qu'un cookie ?</h2>

                <p>Un cookie est un bloc de données de taille réduite déposé, sur le disque dur du terminal de l’internaute (ordinateur, smartphone, tablettes etc.), par le serveur du site visité par celui-ci.
                    L’éditeur d’un site comme http://corbeillesdor.com peut ainsi être amené à déposer un fichier texte (ou cookie) sur l’ordinateur d’un internaute, lors de la visite par ce dernier de son site internet.
                    Ce fichier texte comprend généralement :</p>

                <ul>

                    <li>le nom du serveur qui a déposé le cookie ;</li>

                    <li>un identifiant sous forme de numéro unique ;</li>

                    <li>éventuellement une date d’expiration.</li>

                </ul>

                <p>Pendant la durée de validité du cookie, un tel fichier texte va permettre à http://corbeillesdor.com d’identifier l’ordinateur de l’internaute lors de ses prochaines visites.</p>
                
                <h2>Accord necessaire pour l'utilisation des cookies</h2>

                <p>Tout site internet doit recueillir l’accord de l’internaute avant le dépôt de cookies.
                    En pratique, un message doit apparaître quand l’internaute se connecte au site pour la première fois pour lui indiquer comment accepter ou au contraire refuser les cookies.
                    Les choix de l’internaute peuvent cependant être modifiés à tout moment (voir la rubrique dédiée ci-dessous).</p>
                
                <h2>À quoi servent les cookies utilisés sur notre site</h2>

                <p>Lors de votre connexion au site http://corbeillesdor.com, le site peut être amené à installer divers cookies sur votre terminal. Ce dispositif a pour objectif de reconnaître votre navigateur pendant la durée de validité du cookie concerné et de nous permettre de mettre en place les actions suivantes :</p>

                <ul>
                    <li>– Adapter la présentation du site à vos préférences d’affichage selon les matériels et logiciels de visualisation ou de lecture de votre terminal (langue utilisée, résolution d’affichage, etc.) ;</li>
                </ul>
                
                <h2>Comment configurer votre logiciel de navigation pour gérer les cookies ?</h2>

                <p>2 types de configuration de votre logiciel de navigation sont possibles :</p>

                <ul>
                    <li>Configurer votre logiciel de navigation pour accepter/rejeter les cookies : soit systématiquement, soit selon leur émetteur.</li>
                    <li>Configurer votre logiciel de navigation de façon à ce que l’acceptation/le refus des cookies vous soient proposés ponctuellement, avant chaque éventuel enregistrement d’un cookie sur votre terminal.</li>
                </ul>

                <p>Pour accéder à la configuration de votre logiciel de navigation, vous devez vous rendre dans le menu d’aide de votre navigateur pour savoir de quelle manière modifier vos souhaits en matière de cookies.
                    Voici quelques liens redirigeant vers des logiciels de navigation fréquemment utilisés :</p>

                <ul>
                    <li><a href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies">Internet Explorer™,</a></li>
                    <li><a href="http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html">Safari™,</a></li>
                    <li><a href="http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647">Chrome™,</a></li>
                    <li><a href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies">Firefox™,</a></li>
                    <li><a href="http://help.opera.com/Windows/10.20/fr/cookies.html">Opera™,</a></li>
                </ul>
                
                <h2>Droit applicable</h2>

                <p>Le présent site est soumis à la législation française.</p>

            </div>

            <Spacefooter/>
            
        </div>
    );
};

export default LegalPage;