import React from "react";

import HomeHeader from "../components/homeheader.jsx";
import NavBar from "../components/navbar.jsx";
import Footer from "../components/footer.jsx";
import Spaceheader from "../components/spaceheader.jsx";
import Spacefooter from "../components/spacefooter.jsx";

import PartenairesGrid from "../components/partenairesGrid.jsx"

import './Partenaires.css';

//Functional Component 
const PartenairesPage = () => {
  return (
    <div className="Container">
        
        <Spaceheader/>
        
        <div style={{"textAlign": "center", "alignItem": "center", "justifyContent": "center", "display": "flex", "flex" : "1"}}>
            <h1 style={{"color": "white", "alignSelf": "center"}}>Nos partenaires</h1>
        </div>

        <PartenairesGrid />

        <Spacefooter />

    </div>
  );
};

export default PartenairesPage;