import React from 'react';
import NavBar from "../components/navbar.jsx";
import Spaceheader from "../components/spaceheader.jsx";
import Spacefooter from "../components/spacefooter.jsx";
import FinalisteGrid from "../components/finalisteGrid.jsx"

import './404.css';
import './Jury.css';

const JuryPage = () => {
    return (
        <div className="JuryMasterContainer">

            <Spaceheader/>

            <h1>Liste des 12 finalistes</h1>

            <div className="JuryContainer">

                <FinalisteGrid/>

            </div>

            <Spacefooter/>
                 
        </div>
    );
};

export default JuryPage;