import React from "react";
import {
    Link
} from 'react-router-dom'
import JuryElem from "./juryElem.jsx";

import './juryGrid.css';
import './classementGrid.css';

//Functional Component 
const JuryGrid = () => {

    return (
    <div className="juryContainer">

        <h1>Résultats et classement du concours</h1>

        <div className="jury first">
            <JuryElem candidat={true} mainJury={true} name="CEAUX Fabrice" description="1ère place : Médaille d'Or" img="candidats/ceaux.jpg"/>
        </div>

        <div className="juryGrid">
                      
            <div className="jury second">
                <JuryElem candidat={true} name="LEMAIRE Samira" description="2ème place : Médaille d'Argent" img="candidats/lemaire.jpg"/>
            </div>
            <div className="jury third">
                <JuryElem candidat={true} name="COUTAZ-REPLAND Florence" description="3ème place : Médaille de Bronze" img="candidats/coutaz.jpg"/>
            </div>
        </div>
        <div className="juryGrid">
            <div className="jury talent">
                <JuryElem candidat={true} name="COUAPEL Nicolas" description="Titré talent d'avenir" img="candidats/couapel.jpg"/>
            </div>
            <div className="jury heart">
                <JuryElem candidat={true} name="DELICOURT-MORANTIN Christiane" description="Coup de Coeur du Parrain" img="candidats/delicourt.jpg"/>
            </div>
        </div>
    </div>
    );
};

export default JuryGrid;