import React from "react";

import './loading.css';

//Functional Component 
const Loading = () => {

    return (
        <div className="loading">
            <div></div>
        </div>  
    );
};

export default Loading;