import React from "react";
import {
    Link
} from 'react-router-dom'
import JuryElem from "./juryElem.jsx";

import './juryGrid.css';

//Functional Component 
const JuryGrid = () => {

    return (
    <div className="juryContainer">

        <div className="juryGrid">

                      
            <div className="jury">
                <JuryElem candidat={true} name="ALTUNTAS Mohammed" img="candidats/altuntas.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="AUBE Jean-Philippe" img="candidats/aube.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="CEAUX Fabrice" img="candidats/ceaux.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="COUAPEL Nicolas" img="candidats/couapel.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="COUTAZ-REPLAND Florence" img="candidats/coutaz.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="DECORDIER Aurélie" img="candidats/decordier.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="DELICOURT-MORANTIN Christiane" img="candidats/delicourt.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="LABOUILLE Arnaud" img="candidats/labouille.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="LANGLOIS Rémi" img="candidats/langlois.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="LEMAIRE Samira" img="candidats/lemaire.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="MONNIER Angélique" img="candidats/monnier.jpg"/>
            </div>
            <div className="jury">
                <JuryElem candidat={true} name="PASTEAU Joseph" img="candidats/pasteau.jpg"/>
            </div>
        </div>
    </div>
    );
};

export default JuryGrid;