import React from 'react';

import "./form.css"

export default class Form extends React.Component {
  
  constructor(props) {
	super(props);
	this.state = { nom: '', prenom: '', mail: '', phone: '', motivation: '', entreprise: '' };
    this.handleChangeNom = this.handleChangeNom.bind(this);
    this.handleChangePrenom = this.handleChangePrenom.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeMail = this.handleChangeMail.bind(this);
    this.handleChangeMotivation = this.handleChangeMotivation.bind(this);
    this.handleChangeEntreprise = this.handleChangeEntreprise.bind(this);
	  this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {    
    return (
      <form className="FormulaireContainer">

        <div>

              <p className="TitreInscription">Nom</p>

              <input
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangeNom}
                  placeholder="Nom"
                  required
                  value={this.state.feedback}
                  className="form-control"
              />

              <p className="TitreInscription">Prénom</p>

              <input
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangePrenom}
                  placeholder="Prénom"
                  required
                  value={this.state.feedback}
                  className="form-control"
              />

              <p className="TitreInscription">Adresse e-mail</p>

              <input
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangeMail}
                  placeholder="Adresse mail"
                  required
                  value={this.state.feedback}
                  className="form-control"
              />

              <p className="TitreInscription">Numéro de téléphone</p>

              <input
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangePhone}
                  placeholder="N° de téléphone"
                  required
                  value={this.state.feedback}
                  className="form-control"
              />

              <p className="TitreInscription">Entreprise</p>

              <input
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangeEntreprise}
                  placeholder="Nom de l'entreprise"
                  required
                  value={this.state.feedback}
                  className="form-control"
              />

              <p className="TitreInscription">Motivation</p>

              <textarea
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangeMotivation}
                  placeholder="Votre motivation"
                  required
                  value={this.state.feedback}
                  className="form-control-fat"
              />

        </div>

        <button type="button" className="ButtonInscription" onClick={this.handleSubmit} >S'inscrire</button>
      </form>
    )
  }

  handleChangeEntreprise(event) {
    this.setState({entreprise: event.target.value})
  }
  
  handleChangePhone(event) {
    this.setState({phone: event.target.value})
  }

  handleChangeNom(event) {
    this.setState({nom: event.target.value})
  }
  handleChangePrenom(event) {
    this.setState({prenom: event.target.value})
  }
  handleChangeMail(event) {
    this.setState({mail: event.target.value})
  }
  handleChangeMotivation(event) {
    this.setState({motivation: event.target.value})
  }

  handleSubmit (event) {
    const emptyValue = (elem) => (elem == '')
    const dataValues = [this.state.nom, this.state.prenom, this.state.mail, this.state.motivation, this.state.phone, this.state.entreprise];
    if ( dataValues.some(emptyValue) )
    {
      alert('Veuillez renseigner tous les champs pour vous inscrire.')
      return
    }
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    this.sendFeedback(templateId, {message: this.state.motivation, from_nom: this.state.nom, from_prenom: this.state.prenom, 
      from_entreprise: this.state.entreprise, from_mail: this.state.mail, from_phone: this.state.phone})
  }

  sendFeedback (templateId, variables) {

    window.emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      variables,
      process.env.REACT_APP_EMAILJS_USER_ID,
      ).then(res => {
        alert('Votre inscription a bien été envoyée. Vous allez recevoir un mail de confirmation.');
        console.log(this.props.history.push('/'));
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => {
        alert("Inscription non validée, si vous êtes sur mobile, essayez sur ordinateur. Si le soucis persiste, contactez organisation@corbeillesdor.com", err);
      })
    
  }
  
}