import React from 'react';

import NavBar from "../components/navbar.jsx";

import './404.css';

const NotFoundPage = () => {
    return (
        <div className="Container">
            
            <div style={{"textAlign": "center", "alignItem": "center", "justifyContent": "center", "display": "flex", "flex" : "1", "flexDirection": "column"}}>
                <h1 style={{"color": "white", "alignSelf": "center"}}>404 Page introuvable</h1>
            </div>
            
        </div>
    );
};

export default NotFoundPage;