import React from 'react';
import NavBar from "../components/navbar.jsx";
import Spaceheader from "../components/spaceheader.jsx";
import Spacefooter from "../components/spacefooter.jsx";
import JuryGrid from "../components/juryGrid.jsx"

import './404.css';
import './Jury.css';

const JuryPage = () => {
    return (
        <div className="JuryMasterContainer">

            <Spaceheader/>

            <h1>Un jury d'exception</h1>

            <div className="JuryContainer">

                <JuryGrid/>

            </div>

            <Spacefooter/>
                 
        </div>
    );
};

export default JuryPage;