import React from "react";
import { useState, useRef, useEffect, useCallback } from 'react';


import {
  Link
} from 'react-router-dom'

import HomeHeader from "../components/homeheader.jsx";
import Navbar from "../components/nav/Navbar.jsx";
import Spaceheader from "../components/spaceheader.jsx";
import Spacefooter from "../components/spacefooter.jsx";
import ClassementGrid from "../components/classementGrid.jsx";

import './index.css';

//Functional Component 
const MainPage = () => {

  /*const tileApparition = (tilename) => {
    var tile = document.getElementById(tilename);
    if (window.pageYOffset > tile.offsetTop - ( window.innerHeight - window.innerHeight * 0.1 ))
    {
      tile.style.opacity = 1;
      tile.style.transform = 'scale(1)';
    }
    else
    {
      tile.style.opacity = 0;
      tile.style.transform = 'scale(0.8)';
    }
  }

  const scrollHandler = useCallback(
    () => { 
      tileApparition("Results-Tile-1");
      tileApparition("Results-Tile-2");
      tileApparition("Results-Tile-3");
      tileApparition("Results-Tile-4");
      tileApparition("Results-Tile-5");
      tileApparition("Results-Tile-6");
      tileApparition("Results-Tile-7");
      tileApparition("Results-Tile-8");
      tileApparition("Results-Tile-9");
      tileApparition("Results-Tile-10");
      tileApparition("Results-Tile-11");
      tileApparition("Results-Tile-12");

    },
    []
  );

  useEffect(() => {
      document.getElementById("Results-Tile-1").style.animationDelay = '1s';
      document.getElementById("Results-Tile-2").style.animationDelay = '0.1s';
      document.getElementById("Results-Tile-3").style.animationDelay = '0.6s';
      document.getElementById("Results-Tile-4").style.animationDelay = '1.8s';
      document.getElementById("Results-Tile-5").style.animationDelay = '0s';
      document.getElementById("Results-Tile-6").style.animationDelay = '1.5s';
      document.getElementById("Results-Tile-7").style.animationDelay = '1s';
      document.getElementById("Results-Tile-8").style.animationDelay = '0.4s';
      document.getElementById("Results-Tile-9").style.animationDelay = '1.6s';
      document.getElementById("Results-Tile-10").style.animationDelay = '0.9s';
      document.getElementById("Results-Tile-11").style.animationDelay = '1.2s';
      document.getElementById("Results-Tile-12").style.animationDelay = '1s';
      window.addEventListener('scroll', scrollHandler);

      // returned function will be called on component unmount 
      return () => {
        window.removeEventListener('scroll', scrollHandler);
      }

  }, [scrollHandler]);
  
  insert this after Link :
  
  
      <Spaceheader/>

      <ClassementGrid/>
      
      */

  

  return (
    <div className="Container">
      <HomeHeader/>

      <Spaceheader/>

      <Link to="/finalistes">
          <button className="button" >Accéder à la liste des finalistes</button>
      </Link>

      <Spaceheader/>

      <ClassementGrid/>

      <Spaceheader/>

      <h3 style={{
                "color": "white",
                "fontSize": '12px'            
            }}>Concours d’exception pour faire briller et valoriser le travail de toute la filière fruits et légumes.</h3>
            
            <p style={{
                "margin":"0px", 
                "color": "rgb(192, 168, 88)",
                "fontSize": '12px'
                }}>Événement organisé par les Meilleurs Ouvriers de France Primeurs.</p>
            <p style={{
                "margin":"0px", 
                "color": "rgb(192, 168, 88)",
                "fontSize": '12px'
            }}>Parrain de cette nouvelle édition Laurent Petit, chef triplement étoilé à Annecy le Vieux.</p>

      <Spaceheader/>

      <div style={{"boxShadow": "0px 0px 50px rgba(192, 168, 88, 0.1)"}}>

      <div className="HistoryContainer">

        <div style={{"flex" : 1, "overflow": "hidden"}}>
          <img className="HomeImg" src="corbeille.jpg"/>
        </div>

        <div className="TextContainer">
          <div className="TextContainerPadding">
            <h2 style={{"color": "rgb(192, 168, 88)"}}>Historique</h2>
            <p style={{"color": "white"}}>Ce concours a été créé en 2018 par Clotilde Jacoulot, Meilleur Ouvrier de France Primeur, afin de mettre en avant le métier de primeur et les créations artistiques de corbeilles de fruits et légumes à l’occasion du Salon Sirha Green. Jeff Gaussens a été le vainqueur de cette première édition.</p>
            <p style={{"color": "white"}}>En 2019, Jérôme Durquet, Meilleur Ouvrier de France Primeur, reprend le flambeau et propose un concours de haute volée réunissant 10 talentueux primeurs sélectionnés pour la finale à l’Exphôtel de Bordeaux. Anne-Lise Valot remporte cette seconde édition (également élue coup de cœur au Salon Sirha Green en 2018).</p>
          </div>
        </div>
        
      </div>

      <div className="TodayContainer">

        <div className="TextContainer">
          <div className="TextContainerPadding">
            <h2 style={{"color": "rgb(192, 168, 88)"}}>Aujourd'hui</h2>
            
            <p style={{"color": "white"}}>La troisième édition, programmée au Marché de gros Corbas à Lyon, est confiée au premier couple MOF Primeur Catherine Botti et Jean-Luc Botti. Cette année l’aventure s’annonce audacieuse et fera briller le travail de toute cette chaîne humaine de la filière fruits et légumes. Entourés de la gagnante 2019 Anne-Lise Valot, Charlotte Entraigues et Eric Fabre tous deux MOF Primeurs, ainsi que de nombreux partenaires, nous continuons de promouvoir les belles valeurs de notre métier de primeur.</p>
            <p style={{"color": "white"}}>Inscrivez-vous pour participer à l'épreuve de qualification. Si votre création est retenue, vous ferez partie des 12 finalistes qui s'affronteront en finale au Marché de gros Corbas à Lyon le lundi 14 juin 2021.</p>
            <p style={{"color": "rgb(192, 168, 88)"}}>Concours destiné aux professionnels de la filière fruits et légumes. Vous êtes primeur en magasin, grossiste, primeur sur marché de plein vent, apprenti... Tentez cette magnifique aventure humaine.</p>
            <p style={{"color": "rgb(192, 168, 88)"}}>Primeur, ce n’est pas UN métier mais bien DES métiers !</p>

          </div>
        </div>

        <div style={{"flex" : 1}}>
          <div className="ImgContainer">
            <img style={{"height" : "60%", "width": "60%", "object-fit" : "contain"}} src="logo.png"/>
          </div>
        </div>
        

      </div>

      <div className="OrgaContainer">

        <div className="TextContainer">
          <div className="TextContainerPadding">
            <h2 style={{"color": "rgb(192, 168, 88)"}}>Un jury d'exception</h2>
            
            <p style={{"color": "white"}}>Co-organisé par Catherine Botti et Jean-Luc Botti, le jury sera composé des Meilleurs Ouvriers de France Primeurs, de partenaires, et du parrain Laurent Petit.</p>

            <Link to="/Jury">
                <button className="button" >Voir le jury</button>
            </Link>
          </div>

        </div>

        <div className="TextContainer">
          <div className="TextContainerPadding">
            <h2 style={{"color": "rgb(192, 168, 88)"}}>Le concours et ses partenaires</h2>
            
            <p style={{"color": "white"}}>Les partenaires du concours Corbeilles d’Or soutiennent l’organisation et le déroulement du concours. Retrouvez ceux qui nous soutiennent.</p>

            <Link to="/Partenaires">
                <button className="button" >Voir les partenaires</button>
            </Link>
          </div>
        </div>

      </div>

      </div>

      <Spaceheader/>
      
      <img className="affiche" src="affiche.jpg"/>

      <Spaceheader/>
      
      
      <h1 style={{"color": "rgb(192, 168, 88)"}}>Accès rapides</h1>

      <div style={{"height" : "60px"}}>
          <a className='DownloadLink' href='reglement.pdf' download="Règlement concours Corbeilles d'Or 2021">Télécharger le règlement en PDF</a>        
      </div>
      
      <Spacefooter/>
    </div>
  );
};

export default MainPage;