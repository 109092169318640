import React from "react";
import {
    Link
} from 'react-router-dom'
import JuryElem from "./juryElem.jsx";

import './partenairesGrid.css';

//Functional Component 
const PartenairesGrid = () => {

    return (
    <div className="partenairesContainer">

        <a className="jury" href="http://www.marchedegros-lyoncorbas.com/fr/" target="_blank">
            <JuryElem mainJury={true} name="" img="partenaires/marchedegroslyon.png"/>
        </a>

        <div className="juryGrid">

            <a className="jury" href="https://blampinfruits.com/" target="_blank">
                <JuryElem name="" description="" img="partenaires/blampin.jpg"/>
            </a>            
            <a className="jury" href="https://www.facebook.com/SaveursDuBoutDuMonde" target="_blank">
                <JuryElem name="" description="" img="partenaires/saveursduboutdumonde.png"/>
            </a>
            <a className="jury" href="https://www.mapa-assurances.fr/" target="_blank">
                <JuryElem name="" description="" img="partenaires/mapa.png"/>
            </a>
            <a className="jury" href="https://www.credit-agricole.fr/ca-centrest/particulier.html" target="_blank">
                <JuryElem name="" description="" img="partenaires/creditagricole.png"/>
            </a>
            <a className="jury" href="http://marchesdegros.com/" target="_blank">
                <JuryElem name="" description="" img="partenaires/marchedegrosdefrance.jpg"/>
            </a>
            <a className="jury" href="https://www.saveurs-commerce.fr/" target="_blank">
                <JuryElem name="" description="" img="partenaires/saveurscommerce.png"/>
            </a>
            <a className="jury" href="https://www.blue-whale.com/fr/" target="_blank">
                <JuryElem name="" description="" img="partenaires/bluewhale.png"/>
            </a>
            <a className="jury" href="https://www.bragard.com/fr/" target="_blank">
                <JuryElem name="" description="" img="partenaires/bragard.jpg"/>
            </a>
            <a className="jury" href="https://www.campusdegroisy.com/" target="_blank">
                <JuryElem name="" description="" img="partenaires/groisy.jpg"/>
            </a>
            <a className="jury" href="https://www.coutellerieframos63.com/" target="_blank">
                <JuryElem name="" description="" img="partenaires/couteaux.jpg"/>
            </a>
            <a className="jury" href="https://www.vitabri.com/" target="_blank">
                <JuryElem name="" description="" img="partenaires/vitabri.jpg"/>
            </a>

        </div>
    </div>
    );
};

export default PartenairesGrid;