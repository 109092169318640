import React from "react";
import {
    Link
} from 'react-router-dom'

import { RiFacebookCircleLine } from 'react-icons/ri';

import '../App.css';
import './footer.css';

//Functional Component 
const Footer = () => {

    return (
    <div className="footer">

        <h2 className="footerNavTitle">Nos partenaires</h2>

        <div className="partenaireContainer">
            
            <a href="http://www.marchedegros-lyoncorbas.com/fr/" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/marchedegroslyon.png"/>
            </a>
            <a href="https://blampinfruits.com/" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/blampin.jpg"/>
            </a>
            <a href="http://marchesdegros.com/" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/marchedegrosdefrance.jpg"/>
            </a>
            <a href="https://www.credit-agricole.fr/ca-centrest/particulier.html" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/creditagricole.png"/>
            </a>
            <a href="https://www.mapa-assurances.fr/" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/mapa.png"/>
            </a>
            <a href="https://www.facebook.com/SaveursDuBoutDuMonde" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/saveursduboutdumonde.png"/>
            </a>
            <a href="https://www.saveurs-commerce.fr/" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/saveurscommerce.png"/>
            </a>
            <a href="https://www.blue-whale.com/fr/" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/bluewhale.png"/>
            </a>
            <a href="https://www.bragard.com/fr/" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/bragard.jpg"/>
            </a>            
            <a className="jury" href="https://www.campusdegroisy.com/" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/groisy.jpg"/>
            </a>
            <a className="jury" href="https://www.coutellerieframos63.com/" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/couteaux.jpg"/>
            </a>
            <a className="jury" href="https://www.vitabri.com/" target="_blank">
                <img style={{"borderRadius":"50%"}} className="partenaire" src="partenaires/vitabri.jpg"/>
            </a>
        </div>

        <a style={{"display":"flex", "textAlign" : "center", "alignItems" : "center", "flexDirection" : "column", "textDecoration": 'none'}} href="https://www.facebook.com/corbeillesdor2019/">
            <p style={{"color":"white"}}>Retrouvez-nous sur Facebook</p>
            <RiFacebookCircleLine size="40" color="white" style={{"padding": "10px"}}/>
        </a>

        <p>Contactez-nous : organisation@corbeillesdor.com</p>

        <Link to="/legal">
            <button className="NavBarButton" >Mentions légales</button>
        </Link>

        <p style={{"margin": "0px", "color":"rgb(192, 168, 88)", "padding": "20px" }}>Une création web de Alexy Carlier - Concours Corbeilles d'Or ©</p>     

    </div>
    );
};

export default Footer;