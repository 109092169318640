import React from 'react';
import logo from './logo.svg';
import './App.css';

import { 
  BrowserRouter, 
  Route, 
  Switch, 
  Link, 
  Redirect 
} from 'react-router-dom'

// Pages
import MainPage from "./pages/index.jsx";
import NotFoundPage from "./pages/404.jsx";
import LegalPage from "./pages/legal.jsx";
import PartenairesPage from "./pages/Partenaires.jsx";
import InscriptionPage from "./pages/Inscription.jsx";
import InvitationPage from "./pages/Invitation.jsx";
import FinalistesPage from "./pages/finalistes.jsx";
import PresentationPage from "./pages/Presentation.jsx";
import JuryPage from "./pages/Jury.jsx";


import Navbar from "./components/nav/Navbar.jsx";
import Footer from "./components/footer.jsx";
import HomeHeader from './components/homeheader';

import ScrollToTop from "./utils/scrollToTop.js";
import TempHeader from './components/tempheader';



function App() {
  
  return (
    /*<BrowserRouter>
      <Route exact path="/">
        <TempHeader/>
      </Route>
    </BrowserRouter>*/
    
    <BrowserRouter>
    
    <Navbar/>

      <ScrollToTop />

      <Switch>
        <Route exact path="/">
          <MainPage/>
        </Route>
        <Route exact path="/Jury">
          <JuryPage/>
        </Route>
        <Route exact path="/Partenaires">
          <PartenairesPage/>
        </ Route>
        <Route exact path="/inscriptions">
          <InvitationPage/>
        </ Route>
        <Route exact path="/finalistes">
          <FinalistesPage/>
        </ Route>
        <Route exact path="/404">
          <NotFoundPage/>
        </ Route>
        <Route exact path="/Legal">
          <LegalPage/>
        </ Route>
        <Redirect to="/404"/>
      </Switch>
      
    <Footer/>
    </BrowserRouter>
  );
}

export default App;
