import React, { useState } from 'react';



import Loading from "../components/loading.jsx";
import InvitationForm from "../form/invitationForm.jsx";
import Spaceheader from "../components/spaceheader.jsx";
import { useHistory } from 'react-router-dom';


import Spacefooter from "../components/spacefooter.jsx";

import './Invitation.css';

const InvitationPage = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    return (
        <div className="InvitationMasterContainer">
            <Spaceheader/>
            
            <div style={{"textAlign": "center", "alignItem": "center", "justifyContent": "center", "display": "flex", "flex" : "1", "flexDirection": "column"}}>
                <h1 style={{"color": "white", "alignSelf": "center"}}>Les inscriptions sont terminées.</h1>
            </div>

            <Spacefooter/>
            
        </div>
    );
};

export default InvitationPage;