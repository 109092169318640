import React from "react";

import { GiFruitBowl } from 'react-icons/gi';

import './spacefooter.css';

//Functional Component 
const Spacefooter = () => {

    return (
    <div className="spacefooter">
    </div>
    );
};

export default Spacefooter;