import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import {
    Link
} from 'react-router-dom'

import '../navbar.css';

const Nav = styled.nav`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  position: absolute;

  .logo {
    padding: 15px 0;
    
  };
  z-index: 2;
`

const Navbar = () => {

  const [ offsetTop, setOffsetTop ] = useState(0)
  const [ navStyleColor, setNavStyleColor ] = useState(0)
  const [ navStyleShadow, setNavStyleShadow ] = useState(0)

  let thisElement = React.createRef()
  
  useEffect(() => {
    window.addEventListener('scroll', () => setOffsetTop(window.scrollY))
  }, [])
  
  useEffect(() => {
    
    if(offsetTop === 0){
      setNavStyleColor("transparent")
      setNavStyleShadow("")
    }
    else{
      setNavStyleColor("rgba(0,0,0,0.75)")
      setNavStyleShadow("0px 0px 10px rgba(0,0,0,0.85)")    
      
  }}, [offsetTop])



  return (
    <Nav style={{"transition": "all 0.6s ease-out", "position": "fixed", "backgroundColor":navStyleColor, "boxShadow":navStyleShadow}}>
      <Link to="/">
                <img src={"title.png"} className="NavLogo"/>
      </Link>
      <Burger />
    </Nav>
  )
}

export default Navbar