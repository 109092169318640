import React from "react";
import {
    Link
} from 'react-router-dom'

import './juryElem.css';

//Functional Component 
const JuryElem = (props) => {
    
    const mainJury = props.mainJury;
    const candidat = props.candidat;

    const mainJuryClassName = mainJury ? "juryMainImg" : "juryImg";
    const candidatClassName = candidat ? "candidat" : "";

    return (
    <div className={ mainJury ? "juryMainElem" : "juryElem"}>
        <div/>
        
        <img src={props.img} className={ `${mainJuryClassName} ${candidatClassName}`}/>

        <div className={ mainJury ? "juryMainNameContainer" : "juryNameContainer"}>
            <p className="juryName">{props.name}</p>
            <p className="juryNameInfo">{props.description}</p>
        </div>

    </div>
    );
};

export default JuryElem;