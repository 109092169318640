import React from 'react';

import Loading from "../components/loading.jsx";

import "./form.css"

export default class InvitationForm extends React.Component {
  
  constructor(props) {
    super(props);
    console.log(this)

    this.state = { nom: '', prenom: '', mail: '', phone: '', entreprise: '', loading: false};

    this.handleChangeNom = this.handleChangeNom.bind(this);
    this.handleChangePrenom = this.handleChangePrenom.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeMail = this.handleChangeMail.bind(this);
    this.handleChangeEntreprise = this.handleChangeEntreprise.bind(this);
	  this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {    
    return (
      <form className="FormulaireContainer">

        <div>

              <p className="TitreInscription">Nom</p>

              <input
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangeNom}
                  placeholder="Nom"
                  required
                  value={this.state.feedback}
                  className="form-control"
              />

              <p className="TitreInscription">Prénom</p>

              <input
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangePrenom}
                  placeholder="Prénom"
                  required
                  value={this.state.feedback}
                  className="form-control"
              />

              <p className="TitreInscription">Adresse e-mail</p>

              <input
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangeMail}
                  placeholder="Adresse mail"
                  required
                  value={this.state.feedback}
                  className="form-control"
              />

              <p className="TitreInscription">Numéro de téléphone (portable de préférence)</p>

              <input
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangePhone}
                  placeholder="N° de téléphone"
                  required
                  value={this.state.feedback}
                  className="form-control"
              />

              <p className="TitreInscription">Société / Institution / Association</p>

              <input
                  id="test-mailing"
                  name="test-mailing"
                  onChange={this.handleChangeEntreprise}
                  placeholder="Nom de l'entreprise"
                  required
                  value={this.state.feedback}
                  className="form-control"
              />

        </div>

        <button type="button" className="ButtonInscription" onClick={() => {
          this.setState({loading: true});
          this.handleSubmit()
        }} >S'inscrire pour assister au concours</button>

        { this.state.loading && <Loading /> }

      </form>
    )
  }

  handleChangeEntreprise(event) {
    this.setState({entreprise: event.target.value})
  }
  
  handleChangePhone(event) {
    this.setState({phone: event.target.value})
  }

  handleChangeNom(event) {
    this.setState({nom: event.target.value})
  }
  handleChangePrenom(event) {
    this.setState({prenom: event.target.value})
  }
  handleChangeMail(event) {
    this.setState({mail: event.target.value})
  }
  handleChangeMotivation(event) {
    this.setState({motivation: event.target.value})
  }

  async handleSubmit () {
    
    await setTimeout(async () => {  console.log("World!"); }, 5000);

    const emptyValue = (elem) => (elem == '')
    const dataValues = [this.state.nom, this.state.prenom, this.state.mail, this.state.phone, this.state.entreprise];
    if ( dataValues.some(emptyValue) )
    {
      alert('Veuillez renseigner tous les champs pour vous inscrire.') 
      this.setState({loading: false})
      return
    }
    
    this.sendFeedback({message: this.state.motivation, from_nom: this.state.nom, from_prenom: this.state.prenom, 
      from_entreprise: this.state.entreprise, from_mail: this.state.mail, from_phone: this.state.phone})


    
  }

  async sendFeedback (variables) {

    window.emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_INVITATION_EMAILJS_TEMPLATE_ID,
      variables,
      process.env.REACT_APP_EMAILJS_USER_ID,
      ).then(res => {
        alert('Votre inscription a bien été envoyée. Vous allez recevoir un mail de confirmation.');
        this.setState({loading: false})
        console.log(this.props.history.push('/'));
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => {
        //console.log(err)
        alert("Inscription non validée, si vous êtes sur mobile, essayez sur ordinateur. Si le soucis persiste, contactez organisation@corbeillesdor.com", err);
        this.setState({loading: false})
      })

    this.writeOnAirtable()
  
  }

  writeOnAirtable () {
    var Airtable = require('airtable');
    var base = new Airtable({apiKey: process.env.REACT_APP_AIRTABLE_KEY}).base('app5uFwKlxkuEwjpg');

    base('invitations').create([
      {
        "fields": {
          "nom": this.state.nom,
          "prenom": this.state.prenom,
          "societe": this.state.entreprise,
          "tel": this.state.phone,
          "mail": this.state.mail
        }
      }
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
      });
    });
  }
  
}