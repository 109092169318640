import React , { useEffect, useState } from "react";
import {
    Link
} from 'react-router-dom'

import '../App.css';

import './homeheader.css';

//Functional Component 
const HomeHeader = () => {

    const [isDesktop, setDesktop] = useState(window.innerWidth > 820);
    const [isLandscape, setLandscape] = useState(window.innerWidth > window.innerHeight);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 820);
        setLandscape(window.innerWidth > window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const videoSource = "background_video.mp4"

    return (
    <div className="HomeHeaderContainer">

        {isDesktop && 
            <div className="Video" style={{"overflow":"hidden", "display":"flex", "alignItems":"center", "justifyContent":"center", }}>
                <img className="Img1" style={{"width":"100%", "position":"absolute"}} src={"background_img/1.jpg"}/>
                <img className="Img2" style={{"width":"100%", "position":"absolute"}} src={"background_img/2.jpg"}/>
            </div>
        }
     
        { !isDesktop && isLandscape &&
            <div className="Video" style={{"overflow":"hidden", "display":"flex", "alignItems":"center", "justifyContent":"center", }}>
                <img className="Img1" style={{"width":"100%", "position":"absolute"}} src={"background_img/1.jpg"}/>
                <img className="Img2" style={{"width":"100%", "position":"absolute"}} src={"background_img/2.jpg"}/>
            </div>
        }
        
        { !isDesktop && !isLandscape &&
            <div className="Video" style={{"overflow":"hidden", "display":"flex", "alignItems":"center", "justifyContent":"center", }}>
                <img className="Img1" style={{"height":"100%", "position":"absolute"}} src={"background_img/1.jpg"}/>
                <img className="Img2" style={{"height":"100%", "position":"absolute"}} src={"background_img/2.jpg"}/>
            </div>
        }    
        

        <div className="DarkPanel" />
        
        

        <div style={{display: 'flex', zIndex: '1', flexDirection: 'column', color: 'white', textAlign:'center', alignItems: 'center', padding: '5px'}}>

            <img src={"logo.png"} className="Logo" />

            

            <h3 style={{
                "fontSize": !isDesktop && '12px'
            }}>
                Les qualifications sont terminées. Place à la finale !
            </h3>

            

            <h1 style={{"fontSize":"20px"}}>Rendez-vous en finale le 15 Juin.</h1>

            
        </div>

        
        

    </div>
    );
};

export default HomeHeader;